import React from "react";

const FooterNitide = () => {
  return (
    <footer>
      <span>
        Made with 🍋 by{" "}
        <a href="https://www.nitide-studio.com">Nitide Studio</a>
      </span>
    </footer>
  );
};
export default FooterNitide;
